/* line 21, /Users/crtlf/Sites/locma0005.local/www/sites/default/themes/locma_theme/assets/sass/print.sass */
a {
  color: black !important;
  text-decoration: none;
}

/* line 25, /Users/crtlf/Sites/locma0005.local/www/sites/default/themes/locma_theme/assets/sass/print.sass */
img {
  display: block;
}

/* line 28, /Users/crtlf/Sites/locma0005.local/www/sites/default/themes/locma_theme/assets/sass/print.sass */
form,
iframe,
.background,
.btn,
.element-invisible,
.links,
.messages,
#block-back-button,
#block-header-langs,
#block-header-site-name,
#block-recruitment-jobform,
#block-tabs,
#offcanvas,
#footer,
#menu,
#offcanvas-menu {
  display: none;
}
